import {
    AssetDto,
    ContentDto,
    ContentTypeDetailsDto,
    FieldDto,
    NotificationDetails,
    NotificationOption,
    ScheduleJobDto
} from "@civicplus/hcms-api-sdk/dist/sdk";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import DateTimePicker from "@civicplus/preamble-ui/lib/DateTimePicker";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import RadioGroup from "@civicplus/preamble-ui/lib/RadioGroup";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import enhanceWithValidation, {
    maxValueDateValidation,
    minValueDateValidation,
    requiredValidation,
    validDateValidation
} from "@civicplus/preamble-ui/lib/Validations";
import React, { useEffect, useMemo, useState } from "react";

export function displayable(field: FieldDto): string {
    return field.properties.label || field.name;
}

const EnhancedDatePicker = enhanceWithValidation(DateTimePicker);

export interface SchedulerProps {
    id?: string;
    schema?: ContentTypeDetailsDto;
    item: Item;
    status?: string;
    onConfirm: (item: ContentDto | AssetDto) => void;
    onCancel: () => void;
    defaultEmailSubject?: string;
    defaultEmailIntroduction?: string;
    defaultTextMessage?: string;
}

export enum HCMSScheduleOption {
    Now,
    Scheduled
}

type Item = ContentDto | AssetDto;

interface valuesType {
    notificationDetails?: NotificationDetails;
    scheduleJob?: ScheduleJobDto;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingTop: theme.spacing(2)
    }
}));

export const Scheduler: React.FC<SchedulerProps> = (props) => {
    const classes = useStyles(props);
    const [values, setValues] = useState<valuesType>({
        notificationDetails: props.item.notificationDetails,
        scheduleJob: props.item.scheduleJob
    });

    const [schedule, setSchedule] = useState(
        props.item.scheduleJob?.dueTime ? HCMSScheduleOption.Scheduled : HCMSScheduleOption.Now
    );

    const [notification, setNotification] = useState<NotificationOption>(
        props.item.notificationDetails?.notificationOption ?? NotificationOption.None
    );

    useEffect(() => {
        // We need to set notification option defaults if the user didn't type anything, and a default was provided.
        // We are specifically checking undefined, because we do not want to override anything the user has done
        const notificationDetails = { ...(props.item?.notificationDetails || {}) } as NotificationDetails;

        if (notificationDetails.subject === undefined && props.defaultEmailSubject) {
            notificationDetails.subject = props.defaultEmailSubject;
        }
        if (notificationDetails.emailIntroduction === undefined && props.defaultEmailIntroduction) {
            notificationDetails.emailIntroduction = props.defaultEmailIntroduction;
        }
        if (notificationDetails.smsMessage === undefined && props.defaultTextMessage) {
            notificationDetails.smsMessage = props.defaultTextMessage;
        }

        setValues({
            notificationDetails: notificationDetails,
            scheduleJob: props.item.scheduleJob
        });
    }, [props.item, props.defaultEmailSubject, props.defaultEmailIntroduction, props.defaultTextMessage]);

    const onConfirm = (): void => {
        const item = { ...props.item } as Item;
        item.notificationDetails = values.notificationDetails as NotificationDetails;
        item.scheduleJob = values.scheduleJob as ScheduleJobDto;
        props.onConfirm(item);
    };
    const updateSchedule = (e: { target: { value: string } }): void => {
        const option = parseInt(e.target.value, 10);
        setSchedule(option);
        if (option === HCMSScheduleOption.Now) {
            if (values.scheduleJob) {
                setValues((prev): valuesType => {
                    const scheduleJob = { ...prev.scheduleJob, dueTime: undefined } as ScheduleJobDto;
                    return { ...prev, scheduleJob };
                });
            }
        }
    };

    const canSubmit = (): boolean => {
        if (schedule === HCMSScheduleOption.Scheduled && !values.scheduleJob?.dueTime) {
            return false;
        }
        return true;
    };

    const scheduleOptions = useMemo(
        () => [
            {
                label: `Set to ${props.status} immediately.`,
                value: HCMSScheduleOption.Now,
                id: "nopt1"
            },
            {
                label: `Set to ${props.status} at a later date.`,
                value: HCMSScheduleOption.Scheduled,
                id: "nopt2"
            }
        ],
        [props.status]
    );

    const notificationOptions = useMemo(() => {
        if (props.status !== "Published") {
            return [];
        }
        const options: React.ComponentProps<typeof RadioGroup>["options"] = [
            { id: "op1", value: NotificationOption.None, label: "Do not send notification" },
            {
                id: "op2",
                value: NotificationOption.OnPublishDate,
                color: "default",
                label: "Send notification on publish date"
            }
        ];

        if (props.schema) {
            const date = props.schema.fields.find(
                (w) => w.properties.fieldType === "DateTime" || w.properties.fieldType === "DateTimeRange"
            );
            if (date) {
                options.push({
                    id: "op3",
                    value: NotificationOption.DaysInAdvance,
                    color: "default",
                    label: `Send based on ${displayable(date)} field`
                });
            }
        }
        return options;
    }, [props.status, props.schema]);

    const showExtraDetails = notification !== NotificationOption.None;
    return (
        <div id={props.id} className="prmbl-scheduler">
            <Grid container={true} spacing={2}>
                <Grid xs={showExtraDetails ? 5 : 12}>
                    <Typography>Scheduling</Typography>
                    <RadioGroup
                        id="schedule-options"
                        className="prmbl-scheduler-scheduleOptions"
                        name="schedule-options"
                        options={scheduleOptions}
                        onChange={updateSchedule}
                        value={schedule}
                    />
                    {schedule === HCMSScheduleOption.Scheduled && (
                        <EnhancedDatePicker
                            className="prmbl-scheduler-changeStatusDate"
                            label="Publish Date"
                            format="MM/dd/yyyy h:mm a"
                            onChange={(e: React.ChangeEvent, date: Date): void => {
                                const value = date;
                                const scheduleJob = {
                                    status: props.status,
                                    dueTime: value,
                                    scheduledBy: ""
                                } as ScheduleJobDto;
                                setValues((prev) => ({ ...prev, scheduleJob }));
                            }}
                            value={values.scheduleJob?.dueTime}
                            require={true}
                            validations={[
                                requiredValidation,
                                validDateValidation,
                                minValueDateValidation,
                                maxValueDateValidation
                            ]}
                            keyboard={true}
                            minValue={new Date()}
                            pickerType="dateTime"
                        />
                    )}
                    {notificationOptions.length > 0 && (
                        <div className={classes.wrapper}>
                            <Typography>Notify Your Users</Typography>
                            <RadioGroup
                                id="notification-options"
                                className="prmbl-scheduler-notificationOptions"
                                name="notifications-options"
                                options={notificationOptions}
                                onChange={(e): void => {
                                    const option = e.target.value as NotificationOption;
                                    const notificationDetails =
                                        values.notificationDetails ?? ({} as NotificationDetails);
                                    notificationDetails.notificationOption = option;
                                    setValues((prev) => ({ ...prev, notificationDetails }));
                                    setNotification(option);
                                }}
                                value={notification as string}
                            />
                        </div>
                    )}
                </Grid>
                {showExtraDetails && (
                    <Grid className="prmbl-scheduler-notificationDetails" xs={7}>
                        <Typography>Notification Details</Typography>
                        <Grid container={true} spacing={2}>
                            {notification === NotificationOption.DaysInAdvance && (
                                <Grid container={true}>
                                    <TextInput
                                        className="prmbl-scheduler-daysInAdvance"
                                        type="number"
                                        value={values.notificationDetails?.daysInAdvance?.toString() ?? "0"}
                                        label="Days in Advance"
                                        onChange={(e): void => {
                                            const notificationDetails =
                                                values.notificationDetails ?? ({} as NotificationDetails);
                                            notificationDetails.daysInAdvance = parseInt(e.target.value, 10);
                                            setValues((prev) => ({ ...prev, notificationDetails }));
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid container={true}>
                                <TextInput
                                    className="prmbl-scheduler-emailSubject"
                                    type="text"
                                    value={
                                        values.notificationDetails?.subject !== undefined
                                            ? values.notificationDetails?.subject
                                            : props.defaultEmailSubject
                                    }
                                    label="Email Subject"
                                    onChange={(e): void => {
                                        const notificationDetails =
                                            values.notificationDetails ?? ({} as NotificationDetails);
                                        notificationDetails.subject = e.target.value;
                                        setValues((prev) => ({ ...prev, notificationDetails }));
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid container={true}>
                                <TextInput
                                    className="prmbl-scheduler-emailIntroduction"
                                    type="text"
                                    value={
                                        values.notificationDetails?.emailIntroduction !== undefined
                                            ? values.notificationDetails?.emailIntroduction
                                            : props.defaultEmailIntroduction
                                    }
                                    label="Email Introduction"
                                    onChange={(e): void => {
                                        const notificationDetails =
                                            values.notificationDetails ?? ({} as NotificationDetails);
                                        notificationDetails.emailIntroduction = e.target.value;
                                        setValues((prev) => ({ ...prev, notificationDetails }));
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid container={true}>
                                <TextInput
                                    className="prmbl-scheduler-textMessage"
                                    type="text"
                                    value={
                                        values.notificationDetails?.smsMessage !== undefined
                                            ? values.notificationDetails?.smsMessage
                                            : props.defaultTextMessage
                                    }
                                    label="Text Message (SMS)"
                                    onChange={(e): void => {
                                        const notificationDetails =
                                            values.notificationDetails ?? ({} as NotificationDetails);
                                        notificationDetails.smsMessage = e.target.value;
                                        setValues((prev) => ({ ...prev, notificationDetails }));
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <ButtonGroup layout="right">
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button color="primary" onClick={onConfirm} disabled={!canSubmit()}>
                    Confirm
                </Button>
            </ButtonGroup>
        </div>
    );
};
