export interface HcmsCategory {
    id: string;
    name: string;
    version: number;
}

export class HcmsPermissionSet {
    constructor(public readonly id: string, public readonly name: string, public readonly userPermissions: string[]) {}
}

export enum HcmsStatus {
    Deleted = "Deleted",
    Draft = "Draft",
    Submitted = "Submitted",
    Declined = "Declined",
    Archived = "Archived",
    Published = "Published"
}
